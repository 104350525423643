import { stAnalytics } from "@repo/analytics";
import { useNavigate, useParams } from "@solidjs/router";
import { type Component, createMemo, For, Show, Suspense } from "solid-js";
import { Spinner } from "~/components/Loaders";
import { ListedChat } from "~/components/SideBar/ListedChat";
import { SideBarSectionAccordion } from "~/components/SideBar/SideBarSectionAccordion";
import { getScreenType } from "~/lib/ui/getScreenType";
import { urls } from "~/lib/urls";
import { useWire } from "~/wire";

export const SidebarChats: Component = (props) => {
  const wire = useWire();
  const navigate = useNavigate();
  const [threads, { refetch: refetchRecentThreads }] = wire.services.threads.threadsListResource;
  const chats = createMemo(() => threads()?.data.result.entities.map((t) => t.data));

  // TODO: Better approach
  const threadCollection = (id: string) =>
    wire.services.collections.getCollection(
      (threads()?.data.result.entities.find((t) => t.data.threadId === id)?.collectionIds || []).at(-1) || "",
    )?.label || "";

  const params = useParams();
  const isSelected = (id: string) => {
    return params.thread_id === id;
  };
  return (
    <Suspense fallback={<Spinner class="size-6" />}>
      <Show when={(chats() ?? []).length > 0}>
        <SideBarSectionAccordion label="Recent chats" initiallyOpen depth={0}>
          <For each={chats()}>
            {(chat) => (
              <li>
                <ListedChat
                  onClick={() => {
                    stAnalytics.track("click_tracking", {
                      cta: "navigate_to_chat",
                      position: "left_drawer",
                      screen: getScreenType(),
                    });
                    navigate(urls.threadV2(chat.threadId));
                  }}
                  label={chat.label}
                  collection={threadCollection(chat.threadId)}
                  selected={isSelected(chat.threadId)}
                />
              </li>
            )}
          </For>
        </SideBarSectionAccordion>
      </Show>
    </Suspense>
  );
};
